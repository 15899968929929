<template>
  <div class="animated">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Master Seat layout</strong>
            <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit">
            <b-row id="showlayout">
              <b-col sm="6">
                <b-form-group>
                  <label for="screen">
                    Grid Dimensions (Column X Row)
                    <span class="text-danger">*</span>
                  </label>
                  <div class="d-flex">
                    <b-form-input  type="number" step="any" v-model="sncols"></b-form-input>X
                    <b-form-input  type="number" step="any" maxlength="1" v-model="snrows"></b-form-input>
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group class="mt-4">
                  <b-button
                    :disabled="snrows != 0 && sncols != 0 ? false: true"
                    @click="generateLayout()"
                    size="sm"
                    variant="primary"
                  >
                    <i class="fa fa-dot-circle-o"></i> Generate
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <div id="sngrid" class="sngrid">
              <b-row>
                <b-col>S: Seat</b-col>
                <b-col>N: No Seat</b-col>
                <b-col>W: Wheel Chair</b-col>
              </b-row>
              <br>
              <br>
              <b-row
                style="text-align: center;
                  background: linear-gradient(to right , rgb(44, 2, 73) 15%, rgb(224, 0, 124));
                  color: #fff;
                  padding: 1%;
                  font-size: 30px;"
              >
                <b-col>CINEMA SCREEN</b-col>
              </b-row>
              <br>
              <br>
              <div v-for="(rindex, rowindex) in mainData.seatData" :key="rowindex" class="row">
                <div class="snsquare">
                  <div class="sninnertext">
                    <input
                      v-if="rowindex != 0"
                      type="text"
                      @change="onChangeChar($event.target.value, rowindex)"
                      v-model="rowNames[rowindex]"
                    >
                  </div>
                </div>
                <div class="snsquare" style="width: 81px;">
                  <b-button
                    v-if="rowindex == 0"
                    style="padding: 0.25rem 0.3rem;"
                    @click="showInputs(status.showInput)"
                    size="sm"
                    variant="primary"
                  >ShowName</b-button>
                  <div class="sninnertext"></div>
                </div>

                <div v-if="rowindex == 0" class="snsquare">
                  <!-- 40 -->
                  <input
                    style="width: 56px;margin-left:0.35rem !important;"
                    class="mx-1"
                    type="text"
                    v-for="(cindex, colIndex) in rindex.values"
                    :key="cindex+colIndex"
                    @change="updateSeatLayout($event.target.value, rowindex, colIndex,cindex.number)"
                    v-model="cindex.number"
                  >
                </div>
                <div
                  v-else
                  class="snsquare"
                  v-for="(cindex, colIndex) in rindex.values"
                  :key="cindex+colIndex"
                >
                  <input
                    v-if="status.showInput"
                    style="width: 56px;"
                    v-model="cindex.name"
                    @focus="onFocusShowName($event, rowindex, colIndex, cindex.name)"
                    @change="onChangeShowName($event, rowindex, colIndex, cindex.name)"
                    class="mx-1"
                    type="text"
                  >
                  <b-form-select
                    v-if="!status.showInput"
                    required
                    id="Seats"
                    :plain="true"
                    :disabled="rowNames[rowindex] == '' ? true: false"
                    v-model="cindex.value"
                    :options="seatsStatusList"
                  ></b-form-select>
                </div>
              </div>
            </div>
            <b-row>
              <b-col sm>
                <b-button :disabled="submitButtonFalg" type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i> Submit
                </b-button>
              </b-col>
              <b-col sm="6">
                <b-button @click="resetValues" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import OrganizationService from "@/services/OrganizationService";
import CinemaService from "@/services/CinemaService";
import ScreenService from "@/services/ScreenService";
import { Switch as cSwitch } from "@coreui/vue";

export default {
  name: "ScreenSeatLayout",
  components: {
    cSwitch
  },
  data() {
    return {
      // Saif New
      lastInputChangedNumber: null,
      submitButtonFalg: true,
      setFlagsTypes: [],
      seatsStatusList: [
        {
          text: "S",
          value: "1"
        },
        {
          text: "N",
          value: "0"
        },
        {
          text: "W",
          value: "3"
        }
      ],
      seatsOptios: [
        {
          text: "Select Movie",
          value: "0"
        }
      ],
      // Saif New

      status: {
        showInput: false
      },
      seat: {
        SeatLayouts: [],
        ScreenID: 0,
        Rows: "",
        Columns: ""
      },
      OrganizationID: 0,
      CinemaID: 0,
      rows: [],
      cols: [],
      // seatStatus: [],
      organizations: [],
      setFlags: [],
      cinemas: [],
      screens: [],
      rowNames: [],
      snrows: 0,
      sncols: 0,
      mainData: {
        seatData: []
      }
    };
  },
  mounted() {
    document.getElementById("sngrid").style.display = "none";
  },
  methods: {
    resetValues: function() {
      this.seat = {
        SeatLayouts: [],
        ScreenID: 0,
        Rows: "",
        Columns: ""
      };
      this.submitButtonFalg = true;
      this.setFlags = [];
      this.setFlagsTypes = [];
      this.cinemas = [];
      this.screens = [];
      this.CinemaID = 0;
      this.seat.ScreenID = 0; //
      this.OrganizationID = 0;
      this.rowNames = [];
      this.snrows = 0;
      this.sncols = 0;
      this.mainData.seatData = [];
      document.getElementById("sngrid").style.display = "none";
    },
    resetSeatLayout: function() {
      this.submitButtonFalg = true;
      this.rowNames = [];
      this.snrows = 0;
      this.sncols = 0;
      this.setFlags = [];
      this.setFlagsTypes = [];
      this.mainData.seatData = [];
      document.getElementById("sngrid").style.display = "none";
    },
    showInputs: function(status) {
      this.status.showInput = !status;
    },
    generateLayout: function() {
      if (this.validation()) {
        this.submitButtonFalg = false;
        document.getElementById("sngrid").style.display = "block";
        this.mainData.seatData = [];
        this.rowNames = [];

        let snrows = this.snrows;
        snrows++;

        for (var i = 0, c = 65; i < snrows; i++) {
          let string = "N/A";
          if (i != 0) {
            string = String.fromCharCode(c);
            c++;
          }
          this.rowNames.push(string);
          this.mainData.seatData.push({
            char: string,
            values: [],
            flag: ""
          });
          for (var j = 0; j < this.sncols; j++) {
            let Obj = {};
            Obj["key"] = string + "_" + i + "_" + j;
            Obj["value"] = "1";
            Obj["number"] = j + 1;
            Obj["name"] = string + (j + 1);
            Obj["type"] = "";
            this.mainData.seatData[i].values.push(Obj);
          }
        }
      } else {
        this.mainData.seatData = [];
        this.rowNames = [];
        document.getElementById("sngrid").style.display = "none";
      }
    },
    validation: function() {
      if (this.snrows == 0) {
        this.showMessageSnackbar("Please Select Grid Row");
        return false;
      } else if (this.snrows > 25) {
        this.showMessageSnackbar("Please Select Grid Row must be minimum 25");
        return false;
      } else if (this.sncols == 0) {
        this.showMessageSnackbar("Please Select Grid Column");
        return false;
      } else if (this.sncols > 25) {
        this.showMessageSnackbar(
          "Please Select Grid Column must be minimum 25"
        );
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");

      this.seat.Rows = this.snrows;
      this.seat.Columns = this.sncols;
      let array = this.mainData.seatData;
      let finalArray = [];
      array.map((x, index) => {
        if (index != 0) {
          x.values.map((y, yindex) => {
            finalArray.push({
              row: index,
              columnName: y.name,
              columnNo: yindex + 1,
              type: parseInt(y.type),
              status: y.value
            });
          });
        }
      });
      this.seat.SeatLayouts = finalArray;
      // console.log("Here Final Lay>>", finalArray);
      console.log("Here Final Lay>>", JSON.stringify(finalArray));

      // ScreenService.addSeatLayout(this.seat)
      //   .then(response => {
      //     const { data } = response;
      //     this.showMessageSnackbar(data.Message);
      //     if (data.Status) {
      //       this.resetValues();
      //     }
      //   })
      //   .catch(error => {
      //     this.showMessageSnackbar(
      //       "Oops! Something went wrong. kindly contact your administrator"
      //     );
      //   });
    },
    onFocusShowName: function(event, rowIndex, colIndex, value) {
      // console.log("check focus", event, rowIndex, colIndex, value);
      this.lastInputChangedNumber = this.mainData.seatData[rowIndex].values[
        colIndex
      ].number;
    },
    onChangeShowName: function(event, rowIndex, colIndex, value) {
      if (event.target.value.length > 0) {
        this.mainData.seatData[rowIndex].values[colIndex].name =
          event.target.value;
        this.mainData.seatData[rowIndex].values[colIndex].value = "1";
      } else {
        this.mainData.seatData[rowIndex].values[colIndex].name =
          event.target.value;
        this.mainData.seatData[rowIndex].values[colIndex].value = "0";
      }
      // let valuesLength = this.mainData.seatData[rowIndex].values.length;
      // let lastChangeNumber = this.mainData.seatData[rowIndex].values[colIndex]
      //   .number;
      // let charName = this.mainData.seatData[rowIndex].char;
      // for (let i = colIndex; i < valuesLength; i++) {
      //   if (i == colIndex) {
      //     console.log("event.target.value.length", event.target.value.length);
      //     // if (event.target.value.length > 0) {
      //     this.mainData.seatData[rowIndex].values[i].name = "";
      //     this.mainData.seatData[rowIndex].values[i].number = "";
      //     // this.mainData.seatData[rowIndex].values[i].type = ""
      //     this.mainData.seatData[rowIndex].values[i].value = "0";
      //     // } else {
      //     //   this.mainData.seatData[rowIndex].values[i].name =
      //     //     event.target.value + lastChangeNumber;
      //     //   lastChangeNumber++;
      //     // }
      //   } else if (this.mainData.seatData[rowIndex].values[i].number !== "") {
      //     this.mainData.seatData[rowIndex].values[i].name =
      //       charName + lastChangeNumber;
      //     this.mainData.seatData[rowIndex].values[i].number = lastChangeNumber;
      //     lastChangeNumber++;
      //   }
      // }
      console.log(
        "this.mainData.seatData[rowIndex]",
        this.mainData.seatData[rowIndex]
      );
    },
    //
    updateSeatLayout: function(value, rowIndex, colIndex, number) {
      // console.log(
      //   "value, rowIndex, colIndex, number",
      //   value,
      //   rowIndex,
      //   colIndex,
      //   number
      // );
      // console.log("Herer >> ", value, rowIndex, colIndex, number);
      // console.log(">>>>", this.mainData.seatData);
      if (value.length == 0) {
        //   console.log("this.mainData.seatData :", this.mainData.seatData);
        this.setValues(false, value, colIndex);
      } else {
        this.setValues(true, value, colIndex);
        // this.mainData.seatData.map((x, index) => {
        //   // console.log("Here index and res", x, index)
        //   x.values[colIndex].value = true;
        //   let c = 1;
        //   for (let i = 0; i < x.values.length; i++) {
        //     if (x.values[i].number !== "") {
        //       x.values[i].number = c;
        //       x.values[i].name = this.rowNames[index] + c;
        //       c++;
        //     }
        //   }
        //   x.values[colIndex].number = value;
        // });
      }
    },
    setValues: function(status, value, colIndex) {
      // console.log('value', value)
      // console.log('object', object)
      this.mainData.seatData.map((x, index) => {
        x.values[colIndex].value = status ? "1" : "0"; // status;
        x.values[colIndex].number = value;
        x.values[colIndex].name = value;

        let c = 1;
        for (let i = 0; i < x.values.length; i++) {
          if (x.values[i].number !== "") {
            //   console.log("check Value >>", this.rowNames[index], c);
            x.values[i].number = c;
            x.values[i].name = this.rowNames[index] + c;
            c++;
          }
        }
        x.values[colIndex].number = value;
      });
    },
    // Check Status Hanndler
    statusHandler: function(rowIndex, colIndex, status) {
      // console.log("main Data >>>", this.mainData.seatData)
      // console.log("We are inside the handler",Object.keys(this.mainData.seatData[rowIndex].values[colIndex]))
      // console.log(">>>>>>>>>",)
      // let getKey = Object.keys(this.mainData.seatData[rowIndex].values[colIndex]);
      // console.log("Herer >>", status, !status)
      this.mainData.seatData[rowIndex].values[colIndex].value = status;
      // console.log("getetetet", this.mainData.seatData);
    },
    onChangeType: function(e, rowIndex) {
      this.mainData.seatData[rowIndex].values.map(x => {
        x.type = e;
      });
    },
    // On Select Flag
    onSelecteFlag: function(e, rowIndex) {
      // this.mainData.seatData[rowIndex].flag = e;
      // console.log("Check After Select>>", this.mainData.seatData);
    },
    // Onchange Input Char // Disabled the all row
    onChangeChar: function(e, rowIndex) {
      // if()
      // console.log("Here >> ", e, e.length, rowIndex, this.mainData.seatData);
      this.mainData.seatData[rowIndex].char = e;
      if (e.length == 0) {
        this.mainData.seatData[rowIndex].values.map((x, index) => {
          // let obj = Object.keys(x)
          // x.value = false;
          x.value = "0";
          x.name = e + x.number;
        });
        // console.log("here >> ", this.mainData);
      } else {
        this.mainData.seatData[rowIndex].values.map((x, index) => {
          // let obj = Object.keys(x)
          // x.value = true;
          x.value = "1";
          x.name = e + x.number;
        });
      }
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.sninneractive {
  background-color: red !important;
}

.sninner {
  background-color: gray;
  width: 30px;
  height: 30px;
}
.sninnertext input {
  width: 100%;
}
.sninnertext {
  width: 30px;
  height: 30px;
}
.snsquare {
  padding: 2px;
}
.grid {
  height: 300px;
}
#showlayout + .grid {
  width: 120px;
  height: 120px;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 2px;
  display: none;
}

#showlayout:focus + .grid {
  display: block;
}

#showlayout + .grid .row:first-child .square:first-child .inner {
  background: rgba(0, 0, 0, 0.4);
}

#showlayout + .grid .square:first-child {
  margin-top: 0;
}

#showlayout + .grid .row {
  height: calc(100% / 5);
}
#showlayout + .grid .square {
  height: 100%;
  width: calc(100% / 5);

  display: inline-block;
  margin: -2px 0;
  padding: 1px;
}
#showlayout + .grid .square:hover .inner,
#showlayout + .grid .square.highlight .inner {
  background: rgba(0, 0, 0, 0.4);
}

.inner {
  height: 100%;
  width: 100%;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.2);
}
</style>